<template functional>
  <header
    id="page-title"
    v-bind="data.attrs"
    class="bg-secondary-500 shadow-xl"
    v-if="parent.$route.meta.title"
  >
    <tapered-section class="py-4 px-4">
      <h1 class="text-white">
        <portal-target name="page-title" class="inline">
          {{ parent.$t(`${$options.tPfx}.${parent.$route.meta.title}`) }}
        </portal-target>
        <portal-target name="after-page-title" class="inline" />
      </h1>
    </tapered-section>
  </header>
</template>

<script>
export const translationPrefix = "layout.default.eTitle";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      login: "Login",
      register: "Register",
      apply: "Apply",
      applications: "My applications",
      profile: "My profile",
      questionary: "Visa application form",
      payment: "Payment",
      track: "Track application",
      verify: "Verify visa",
      about: "About",
      'download-visa': "Download Your Visa",
      appeal: "Appeal",
    },
    al: {
      login: "Kyçu",
      register: "Regjistrohu",
      apply: "Apliko",
      applications: "Aplikimet e mia",
      profile: "Profili im",
      questionary: "Formulari i aplikimit për vizë",
      payment: "Pagesa",
      track: "Gjurmo aplikimin",
      verify: "Verifiko vizen",
      about: "Rreth nesh",
      'download-visa': "Shkarkoni Vizën Tuaj",
      appeal: "Apelo"
    },
  },
};

export default {
  tPfx: translationPrefix,
};
</script>

<style>
</style>
