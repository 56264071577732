<template>
  <div class="flex flex-col md:flex-row justify-center">
    <button
      v-if="canDownloadGeneratedVisa"
      :title="$t(`${$options.tPfx}.downloadVisa`)"
      @click="downloadGeneratedVisa"
      class="button button-compact button-green px-3"
    >
      <font-awesome-icon icon="file-download" class="text-white" />
    </button>
    <button
        v-if="canCancel"
        :title="$t(`${$options.tPfx}.cancelApplication`)"
        @click="cancelApplication"
        class="button button-compact button-secondary px-3"
    >
      <font-awesome-icon icon="trash" class="text-white" />
    </button>
    <router-link
      v-if="needsPayment"
      :to="{ name: 'Payment', params: { applicationId: application.id } }"
    >
      <button
        class="button button-compact button-secondary px-3"
        :title="$t(`${$options.tPfx}.pay`)"
      >
        <font-awesome-icon icon="euro-sign" class="text-white" />
      </button>
    </router-link>
    <router-link
      v-if="canAppeal"
      :to="{ name: 'Appeal', params: { applicationId: application.id } }"
    >
      <button
        class="button button-compact button-secondary px-3"
        :title="$t(`${$options.tPfx}.appeal`)"
      >
        <font-awesome-icon icon="gavel" class="text-white" />
      </button>
    </router-link>
    <router-link
      :target="hasActionsOtherThanViewOrEdit ? '_blank' : ''"
      :to="{
        name: 'Questionary',
        params: { applicationId: application.id },
      }"
      class="mt-2 md:ml-2 md:mt-0"
    >
      <button
        class="button button-compact"
        :class="{
          'button-info': !canBeEdited,
          'button-yellow': canBeEdited && isDraft,
          'button-secondary': canBeEdited && hasIrregularities,
        }"
        :title="$t(`${$options.tPfx}.${canBeEdited ? 'edit' : 'view'}`)"
      >
        <font-awesome-icon
          :icon="canBeEdited ? 'edit' : 'eye'"
          class="text-white"
        />
      </button>
    </router-link>
    <!-- Conditionally render more actions based on application status -->
  </div>
</template>

<script>
import {Visa, client, Application} from "@/api";
import downloadFile, { getFileNameFromHeaders } from "@/utils/downloadFile";
import STATUS_ENUM from "@/utils/applicationStatusEnum";

export const translationPrefix = "view.applications.applicationActions";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      view: "View application",
      edit: "Edit application",
      pay: "Make payment",
      downloadVisa: "Download e-visa",
      appeal: "Appeal",
      cancelApplication: "Cancel Application",
      yes: "Yes",
      no: "No",
      cancelApplicationQuestion: "Do you want to cancel this application?",
      applicationCanceledWithSuccess: "Application cancelled successfully!",
      errorMessage: "Something went wrong, please try again.",
    },
    al: {
      view: "Shiko applikimin",
      edit: "Modifiko aplikimin",
      pay: "Bëj pagesen",
      downloadVisa: "Shkarko e-visa",
      appeal: "Apelo",
      yes: "Po",
      no: "Jo",
      cancelApplication: "Anulo Aplikimin",
      cancelApplicationQuestion: "Doni ta anulloni këtë aplikim?",
      applicationCanceledWithSuccess: "Aplikimi u anullua me sukses!",
      errorMessage: "Një gabim i papritur ndodhi, ju lutem provoni përseri.",
    },
  },
};

export default {
  tPfx: translationPrefix,
  props: {
    application: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isDraft() {
      return this.application.status.code === STATUS_ENUM.draft;
    },
    hasIrregularities() {
      return this.application.status.code === STATUS_ENUM.consularNeedChanges;
    },
    canBeEdited() {
      return this.isDraft || this.hasIrregularities;
    },
    needsPayment() {
      return this.application.status.code === STATUS_ENUM.waitingForPayment || this.application.needsFerPayment;
    },
    canDownloadGeneratedVisa() {
      return this.application.status.code === STATUS_ENUM.printedGenerated;
    },
    hasActionsOtherThanViewOrEdit() {
      return this.needsPayment || this.canDownloadGeneratedVisa;
    },
    canAppeal() {
      return this.application.status.code === STATUS_ENUM.rejected;
    },
    canCancel() {
      return this.application.status.code === STATUS_ENUM.open || this.application.status.code === STATUS_ENUM.consularInProgress;
    }
  },
  methods: {
    async cancelApplication() {
      try {
        const confirmDeletion = await this.$swal({
          text: this.$t(`${this.$options.tPfx}.cancelApplicationQuestion`),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t(`${this.$options.tPfx}.yes`),
          cancelButtonText: this.$t(`${this.$options.tPfx}.no`),
          reverseButtons: true
        });

        if (confirmDeletion.isConfirmed) {
          await Application.cancel(this.application.id);

          await this.$swal(
            this.$t(`${this.$options.tPfx}.applicationCanceledWithSuccess`),
          );

          this.$router.go(0);
        }
      } catch (error) {
        console.log(error);
        this.$swal('Error', this.$t(`${this.$options.tPfx}.errorMessage`), 'error');
      }
    },
    async downloadGeneratedVisa() {
      if (!this.canDownloadGeneratedVisa) {
        return;
      }

      const {
        data: temporarySignedUrlResponse,
      } = await Visa.getTemporarySignedUrl(this.application.visa.uuid);

      let temporarySignedUrl =
        temporarySignedUrlResponse.data.temporarySignedUrl;

      temporarySignedUrl = temporarySignedUrl.replace('cms.', '');
      temporarySignedUrl = temporarySignedUrl.replace('http:', 'https:');

      const downloadFileResponse = await client.get(temporarySignedUrl, {
        responseType: "blob",
      });

      downloadFile(
        downloadFileResponse.data,
        getFileNameFromHeaders(downloadFileResponse.headers)
      );
    },
  },
};
</script>