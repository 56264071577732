import buildLocale from '@i/buildLocale';

import { translations as EFooter } from '@l/Default/EFooter.vue';
import { translations as WhyEVisa } from '@v/Home/WhyEVisa.vue';
import { translations as VisaApplicationProcess } from '@v/Home/VisaApplicationProcess.vue';
import { translations as ApplyButton } from '@c/ApplyButton.vue';
import { translations as TrackButton } from '@c/TrackButton.vue';
import { translations as VerifyButton } from '@c/VerifyButton.vue';
import { translations as LoginOrRegister } from '@l/Default/Navbar/LoginOrRegister.vue';
import { translations as Login } from '@v/Login.vue';
import { translations as Notifications } from '@l/Default/Navbar/UserProfile/Notifications.vue';
import { translations as DropdownUserProfile } from '@l/Default/Navbar/UserProfile/DropdownUserProfile';
import { translations as InlineUserProfile } from '@l/Default/Navbar/UserProfile/InlineUserProfile';
import { translations as Register } from '@v/Register';
import { translations as TmpPass } from '@v/Home';
import { translations as Apply } from '@v/Apply.vue';
import { translations as EVisaInformation } from '@v/Apply/EVisaInformation.vue';
import { translations as Exemptions } from '@v/Apply/Exemptions.vue';
import { translations as Visas } from '@v/Apply/Visas.vue';
import { translations as VisaCategoryGroup } from '@v/Apply/Visas/VisaCategoryGroup.vue';
import { translations as VisaType } from '@v/Apply/Visas/VisaTypes/VisaType.vue';
import { translations as ProcessPhases } from '@v/Apply/ProcessPhases.vue';
import { translations as FirstPhase } from '@v/Apply/ProcessPhases/FirstPhase.vue';
import { translations as SecondPhase } from '@v/Apply/ProcessPhases/SecondPhase.vue';
import { translations as Notes } from '@v/Apply/ProcessPhases/Notes.vue';
import { translations as ETitle } from '@l/Default/ETitle.vue';
import { translations as Menus } from '@l/Default/Navbar/Menus.vue';
import { translations as Questionary } from '@v/Questionary.vue';
import { translations as Pages } from '@v/Questionary/Pages.vue';
import { translations as Question } from '@v/Questionary/Questions/Question.vue';
import { translations as PageControls } from '@v/Questionary/PageControls.vue';
import { translations as Documents } from '@v/Questionary/Documents.vue';
import { translations as Document } from '@v/Questionary/Documents/Document.vue';
import { translations as SomethingWentWrong } from '@v/SomethingWentWrong.vue';
import { translations as NotFound } from '@v/NotFound.vue';
import { translations as Applications } from '@v/Applications.vue';
import { translations as Profile } from '@v/Profile.vue';
import { translations as ApplicationActions } from '@v/Applications/ApplicationActions.vue';
import { translations as CreateApplicationRedirecter } from '@v/CreateApplicationRedirecter.vue';
import { translations as Payment } from '@v/Payment.vue';
import { translations as MethodSelect } from '@v/Payment/MethodSelect.vue';
import { translations as ApplicationLinks } from '@v/Payment/ApplicationLinks.vue';
import { translations as UploadProofOfPayment } from '@v/Payment/UploadProofOfPayment.vue';
import { translations as PayOnline } from '@v/Payment/PayOnline.vue';
import { translations as Track } from '@v/Track.vue';
import { translations as Verify } from '@v/Verify.vue';
import { translations as DownloadVisa } from '@v/DownloadVisa.vue';
import { translations as DocumentCategory } from '@v/Apply/Visas/VisaTypes/VisaType/DocumentCategories.vue';
import { translations as FileInput } from '@c/FileInput.vue';
import { translations as UploadAppealDocument} from '@v/Appeal/UploadAppealDocument.vue';
import {translations as Appeal} from "@v/Appeal.vue";

export default buildLocale('al', [
  EFooter,
  WhyEVisa,
  VisaApplicationProcess,
  ApplyButton,
  TrackButton,
  VerifyButton,
  LoginOrRegister,
  Login,
  Notifications,
  DropdownUserProfile,
  InlineUserProfile,
  Register,
  TmpPass,
  Apply,
  EVisaInformation,
  Visas,
  VisaCategoryGroup,
  VisaType,
  ProcessPhases,
  FirstPhase,
  SecondPhase,
  Notes,
  Exemptions,
  ETitle,
  Menus,
  Questionary,
  Pages,
  Question,
  PageControls,
  Documents,
  Document,
  SomethingWentWrong,
  NotFound,
  Applications,
  Profile,
  ApplicationActions,
  CreateApplicationRedirecter,
  Payment,
  MethodSelect,
  ApplicationLinks,
  UploadProofOfPayment,
  PayOnline,
  Track,
  Verify,
  DownloadVisa,
  DocumentCategory,
  FileInput,
  Appeal,
  UploadAppealDocument
]);
