import client from "./client";

export default {
  getTemporarySignedUrl(uuid) {
    return client.get(`/visas/${uuid}/temporary-signed-url`);
  },
  verify(uuid) {
    return client.get(`/visas/${uuid}/verify`);
  },
  verifyStatus(serialNumber, passportNumber) {
    return client.get(`/visas/status?serialNumber=${serialNumber}&passportNumber=${passportNumber}`);
  },
};
