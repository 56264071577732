<template functional>
  <router-link :to="{ name: 'Verify' }">
    <button
        class="rounded-md px-6 py-4 bg-secondary-500 font-bold text-white focus:outline-none focus:border focus:border-gray-700"
        :class="[data.class, data.staticClass]"
    >
      {{ parent.$t(`${$options.tPfx}.verify`) }}
    </button>
  </router-link>
</template>

<script>
export const translationPrefix = "component.verify_button";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      verify: "Verify",
    },
    al: {
      verify: "Verifiko",
    },
  },
};

export default {
  tPfx: translationPrefix,
};
</script>
