<template>
  <default-fill-height class="bg-gray-200">
    <tapered-section
        class="px-4"
        :class="{ 'py-32': isShowingMessage, 'py-12': !isShowingMessage }"
    >
      <article
          class="border border-gray-400 rounded-md p-4 md:p-8 flex flex-wrap items-start bg-white"
      >
        <form
            @submit.prevent="checkIfVisaIsValid"
            class="mx-auto w-full md:w-1/3"
        >
          <text-input
              v-model="serialNumber"
              :label="$t(`${$options.tPfx}.serialNumber`)"
              :placeholder="$t(`${$options.tPfx}.serialNumber`)"
              id="serial-number"
              name="serial-number"
              required
          />
          <text-input
              v-model="passportNumber"
              :label="$t(`${$options.tPfx}.passportNumber`)"
              :placeholder="$t(`${$options.tPfx}.passportNumber`)"
              id="passport-number"
              name="passport-number"
              required
          />
          <button type="submit" class="w-full button button-secondary">
            {{ $t(`${$options.tPfx}.verify`) }}
          </button>
        </form>
      </article>
    </tapered-section>
  </default-fill-height>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { Visa } from "@/api";
import { actions, module as flashModule } from "@/store/flash";
import { module as authModule } from "@/store/auth";

export const translationPrefix = "view.verify";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      noVisa: "No visa was found with these details!",
      visaFound: "Visa status: {status}",
      serialNumber: "Serial Number",
      passportNumber: "Passport Number",
      verify: "Verify",
    },
    al: {
      noVisa: "Nuk u gjet asnjë vizë me këto të dhëna!",
      visaFound: "Statusi vizës: {status}",
      serialNumber: "Numri Serial",
      passportNumber: "Numri Pasaportës",
      verify: "Verifiko",
    },
  },
};

export default {
  tPfx: translationPrefix,
  data() {
    return {
      serialNumber: '',
      passportNumber: '',
    };
  },
  computed: {
    ...mapGetters(flashModule, ["isShowingMessage"]),
    ...mapGetters(authModule, ["isLoggedIn"]),
    ...mapState(authModule, ["user"]),
  },
  methods: {
    ...mapActions(flashModule, {
      dispatchDismissFlash: actions.dismiss,
      dispatchShowFlash: actions.show,
    }),
    async checkIfVisaIsValid() {
      try {
        const { data: statusCheckResponse } = await Visa.verifyStatus(
            this.serialNumber,
            this.passportNumber
        );

        this.dispatchDismissFlash();

        this.$swal(
            this.$t(`${this.$options.tPfx}.visaFound`, {
              trackingNumber: this.trackingNumber,
              status:
                  statusCheckResponse.data.application.status.name[this.$i18n.locale],
            })
        );
      } catch (error) {
        await this.dispatchShowFlash({
          class:
              "sm:block md:inline-block border-secondary-500 bg-secondary-200 max-w-lg mx-auto sm:max-w-auto sm:min-w-lg",
          iconClass: "hover:text-secondary-500",
          text: `${this.$options.tPfx}.noVisa`,
          textClass: "avoid-warp",
        });
      }
    },
  },
  async mounted() {
    if (this.trackingNumber) {
      await this.checkIfVisaIsValid();
    }
  },
  beforeRouteLeave(_to, _from, next) {
    this.dispatchDismissFlash();
    next();
  },
};
</script>

<style>
</style>
