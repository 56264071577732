<template>
  <default-fill-height
    class="bg-gray-200"
    :class="{ 'py-8': !isShowingMessage, 'pt-32 pb-8': isShowingMessage }"
    id="questionary"
  >
    <loader
      v-if="isLoadingApplication"
      :loading="isLoadingApplication"
      size="1.5rem"
      margin="4px"
    />
    <tapered-section v-else class="px-4">
      <questionary-title-portal :title="visaTypeTitle[$i18n.locale]" :visa-label="visaLabel" />
      <pages
        :pages="pages"
        :value="page"
        @input="goToPage"
        :only-backwards="pagesOnlyGoBackward"
        class="mb-8"
      />
      <questions
        :application-status-code="applicationStatusCode"
        v-show="!isLastPage"
        :questions="currentPageQuestions"
        :disabled="applicationIsLocked"
        :show-comments="showComments"
        @question-answered="onQuestionAnswered"
        @question-synched="onQuestionSynched"
        class="mb-8"
        id="questions"
      />
      <documents
        v-show="isLastPage"
        :application-id="applicationId"
        :document-categories="documentCategories"
        :disabled="applicationIsLocked"
        :show-comments="showComments"
        @document-uploaded="onDocumentUploaded"
        @document-deleted="onDocumentDeleted"
        @document-size-error="onDocumentSizeError"
        @document-type-error="onDocumentTypeError"
        class="mb-8"
      />
      <page-controls
        :value="page"
        :is-first-page="isFirstPage"
        :is-last-page="isLastPage"
        :is-locked="applicationIsLocked"
        :is-finalizing="isFinalizingApplication"
        :next-button-is-disabled="nextButtonIsDisabled"
        :num-pages="pages.length"
        @next="goToNextPage"
        @back="goToPrevPage"
        @finalize="finalizeApplication"
      />
    </tapered-section>
  </default-fill-height>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Application, Answer } from "@/api";
import { actions as flashActions, module as flashModule } from "@/store/flash";
import scrollToResponsive from "@/utils/scrollToResponsive";
import validateSpecificQuestionCallbacks from "./Questionary/helpers/validateSpecificQuestionCallbacks";
import STATUS_ENUM from "../utils/applicationStatusEnum";
import QUESTION_CODE_ENUM from "./Questionary/helpers/questionCodeEnum";
import DOCUMENT_CATEGORY_ENUM from "./Questionary/helpers/documentCategoryCodeEnum";
import addAuxiliaryPropertiesToQuestion from "./Questionary/helpers/questionAuxiliaryProperties";
import addAuxPropsToDocumentCategories from "./Questionary/helpers/documentCategoryAuxiliaryProperties";
import questionSideEffects from "./Questionary/helpers/questionSideEffects";
import questionSideEffectsAfterServerSync from "./Questionary/helpers/questionSideEffectsAfterServerSync";
import QuestionaryTitlePortal from "./Questionary/QuestionaryTitlePortal.vue";
import Pages from "./Questionary/Pages.vue";
import Questions from "./Questionary/Questions.vue";
import Documents from "./Questionary/Documents.vue";
import PageControls from "./Questionary/PageControls.vue";
import _ from "lodash";
import CONSULAR_REPRESENTATIONS_EMAILS from "@/utils/consularRepresentationEmails";

export const translationPrefix = "view.questionary";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      currentNationalyVisaNotRequired:
        "For the currently selected nationality, visa is not required to travel to the Republic of Albania",
      somethingWentWrong:
        "Something went wrong!",
      somethingWentWrongWithEmailAddress:
        "",
      validationFailed:
        "Please fill all required fields!",
      blockedApplicantPassportNumber:
          "You have used a passport number that is not allowed to apply for visa!",
      electronicVisa: 'Electronic Visa',
      stampedVisa: 'Stamped Visa',
    },
    al: {
      currentNationalyVisaNotRequired:
        "Për shtetësinë e zgjedhur nuk nevojivet vizë për të udhëtuar në Republikën e Shqipërisë",
      somethingWentWrong:
        "Diçka shkoi keq!",
      somethingWentWrongWithEmailAddress:
        "",
      validationFailed:
        "Ju lutem plotësoni të gjithë fushat e detyrueshme!",
      blockedApplicantPassportNumber:
          "Ju keni përdorur një numër pasaporte i cili nuk është i lejuar të aplikojë për vizë!",
      electronicVisa: 'Vizë Elektronike',
      stampedVisa: 'Vizë Pullë',
    },
  },
};

export default {
  tPfx: translationPrefix,
  beforeRouteLeave(_to, _from, next) {
    this.dispatchDismissFlash();
    next();
  },
  components: {
    QuestionaryTitlePortal,
    Pages,
    Questions,
    Documents,
    PageControls,
  },
  props: {
    applicationId: [Number, String],
  },
  data: () => ({
    application: {},
    page: 1,
    isLoadingApplication: false,
    isFinalizingApplication: false,
    currentNationalityDoesNotRequireVisa: false,
    under18DocumentIsRequired: false,
    residencePermitOver12MonthsIsRequired: false,
  }),
  computed: {
    ...mapGetters(flashModule, ["isShowingMessage"]),
    visaTypeTitle() {
      if (!this.application.visaType) {
        return {
          en: "",
          al: "",
        };
      }

      return this.application.visaType.title;
    },
    visaLabel() {
      return this.application.is_electronic
          ? this.$t(`${this.$options.tPfx}.electronicVisa`)
          : this.$t(`${this.$options.tPfx}.stampedVisa`)
    },
    applicationStatusCode() {
      if (!this.application.status) {
        return null;
      }

      return this.application.status.code;
    },
    documentCategories() {
      if (!this.application.visaType) {
        return [];
      }

      return this.application.visaType.documentCategories;
    },
    questions() {
      if (!this.application.visaType) {
        return [];
      }

      return this.application.visaType.questions;
    },
    pages() {
      if (!this.application.visaType) {
        return [];
      }

      const uniquePages = new Set();

      this.questions.forEach((question) => {
        uniquePages.add(question.page);
      });

      // Sort and add 1 last page for documents
      const sortedPages = [...uniquePages].sort();
      const documentsPage = sortedPages[sortedPages.length - 1] + 1;

      sortedPages.push(documentsPage);

      return sortedPages;
    },
    isLastPage() {
      return this.page === this.pages[this.pages.length - 1];
    },
    isFirstPage() {
      return this.page === this.pages[0];
    },
    nextButtonIsDisabled() {
      return this.currentNationalityDoesNotRequireVisa; // || some other condition
    },
    currentPageQuestions() {
      const questions = [];

      for (const question of this.questions) {
        if (question.page === this.page) {
          questions.push(question);
        }
      }

      return questions;
    },
    currentAnswersAreValid() {
      let areValid = true;

      for (const question of this.currentPageQuestions) {
        if (question.error) {
          areValid = false;

          break;
        }
      }

      return areValid;
    },
    showComments() {
      if (!this.application.status) {
        return false;
      }

      return this.applicationStatusCode === STATUS_ENUM.consularNeedChanges;
    },
    userCanMakeChanges() {
      if (!this.application.status) {
        return false;
      }

      return (
        this.applicationStatusCode === STATUS_ENUM.draft ||
        this.applicationStatusCode === STATUS_ENUM.consularNeedChanges
      );
    },
    applicationIsLocked() {
      return !this.userCanMakeChanges;
    },
    pagesOnlyGoBackward() {
      if (!this.application.status) {
        return true;
      }

      return this.applicationStatusCode === STATUS_ENUM.draft;
    },
  },
  methods: {
    scrollToResponsive,
    ...mapActions(flashModule, {
      dispatchShowFlash: flashActions.show,
      dispatchDismissFlash: flashActions.dismiss,
    }),
    ...addAuxiliaryPropertiesToQuestion,
    ...addAuxPropsToDocumentCategories,
    async handleErrorResponse(error) {
      switch (error.response.status) {
        case 404:
          return await this.$router.push({
            name: "Apply",
            hash: "#visa-types",
          });
        case 403:
          return await this.$router.replace({
            name: "Home",
          });
      }
    },
    questionHasAnswer(question) {
      return !!question.answers[0] && !!question.answers[0].answer;
    },
    getQuestionByCode(code) {
      return this.questions.find(
        (questionToCheck) => questionToCheck.code === code
      );
    },
    addEmailOfConsularRepresentationToErrorMessage(consularRepresentationName) {
      if (! consularRepresentationName) {
        consularRepresentationName = 'Default Consular'
      }

      const emailKey = _.camelCase(consularRepresentationName)
      const email = CONSULAR_REPRESENTATIONS_EMAILS[emailKey]

      const englishMessage = ' Please try again or contact this email address: ' + email
      const albanianMessage = ' Ju lutem provoni përsëri ose kontaktoni në adresën: ' + email

      translations.t.en.somethingWentWrongWithEmailAddress =  translations.t.en.somethingWentWrong + englishMessage
      translations.t.al.somethingWentWrongWithEmailAddress =  translations.t.al.somethingWentWrong + albanianMessage
    },
    ...questionSideEffects,
    async applyQuestionSideEffects(question) {
      // ! We are not filtering out the consular representations based on the country of residence for now
      // ! We'll have to re-activate it as a feature once we have migrated all the old data.
      if (question.code === QUESTION_CODE_ENUM.countryOfResidence) {
        return this.applyCountryOfResidenceSideEffects(question);
      }

      if (question.code === QUESTION_CODE_ENUM.currentNationality) {
        return this.applyCurrentNationalitySideEffects(question);
      }

      if (question.code === QUESTION_CODE_ENUM.dateOfBirth) {
        return this.applyDateOfBirthSideEffects(question);
      }

      if (question.code === QUESTION_CODE_ENUM.residencePermit) {
        return this.applyResidencePermitSideEffects(question);
      }

      if (question.code === QUESTION_CODE_ENUM.passportDateOfIssue) {
        return this.applyPassportDateOfIssueSideEffects(question);
      }

      if (question.code === QUESTION_CODE_ENUM.passportDateOfExpiry) {
        return this.applyPassportExpiryDateSideEffects(question);
      }

      if (question.code === QUESTION_CODE_ENUM.dateOfArrival) {
        return this.applyDateOfArrivalSideEffects(question);
      }

      if (question.code === QUESTION_CODE_ENUM.dateOfDeparture) {
        return this.applyDateOfDepartureSideEffects(question);
      }
    },
    async loadApplication() {
      try {
        this.isLoadingApplication = true;

        const {data: applicationResponse} = await Application.get(
            this.applicationId
        );

        this.application = applicationResponse.data.application;

        if (this.application.visaType.categoryGroup === 'D' || this.application.visaType.categoryGroup === 'C') {
          let visaTypeQuestions = this.application.visaType.questions
          let hostDefaultFieldsRequiredForTypeDAndC = [
            QUESTION_CODE_ENUM.hostPhone,
            QUESTION_CODE_ENUM.hostEmail,
            QUESTION_CODE_ENUM.hostName,
            QUESTION_CODE_ENUM.hostSurname,
            QUESTION_CODE_ENUM.hostFullAddress,
            QUESTION_CODE_ENUM.hostPersonalNumber,
            QUESTION_CODE_ENUM.hostExpensesPaidBy,
            QUESTION_CODE_ENUM.hostExpensesPaidByDetails,
            QUESTION_CODE_ENUM.hostMeansOfSupport,
          ]

          visaTypeQuestions = visaTypeQuestions.map(visaTypeQuestion => {
            let visaTypeQuestionCode = visaTypeQuestion.code

            if (hostDefaultFieldsRequiredForTypeDAndC.includes(visaTypeQuestionCode)) {
              visaTypeQuestion.isRequired = 1
              visaTypeQuestion.question.al += ' *'
              visaTypeQuestion.question.en += ' *'
            }

            if (visaTypeQuestionCode === QUESTION_CODE_ENUM.host) {
              this.applyHostSideEffectsAfterServerSync(visaTypeQuestion)
            }

            return visaTypeQuestion
          })

          this.application.visaType.questions = visaTypeQuestions
      }

        //! Add some properties which are needed based on question type
        for (const question of this.application.visaType.questions) {
          this.addAuxPropsToQuestion(question);
        }
        //! I.E. fill options of selects whose list is defined by previous option
        for (const question of this.application.visaType.questions) {
          this.applyQuestionSideEffects(question);
        }

        //! this.$set some error properties
        this.application.visaType.documentCategories.forEach(
          this.addAuxPropsToDocumentCategory
        );

        this.isLoadingApplication = false;
      } catch (error) {
        await this.handleErrorResponse(error);
      }
    },
    ...validateSpecificQuestionCallbacks,
    validateQuestion(question) {
      let isValid = true;
      if (question.isRequired && question.answers.length === 0) {
        question.error = "isRequired";

        isValid = false;
      }

      // ! Apply question specific validation
      if (question.code === QUESTION_CODE_ENUM.dateOfArrival) {
        isValid = isValid && this.validateDateOfArrival(question);
      } else if (question.code === QUESTION_CODE_ENUM.dateOfDeparture) {
        isValid = isValid && this.validateDateOfDeparture(question);
      } else if (question.code === QUESTION_CODE_ENUM.passportDateOfIssue) {
        isValid = isValid && this.validatePassportDateOfIssue(question);
      } else if (question.code === QUESTION_CODE_ENUM.passportDateOfExpiry) {
        isValid = isValid && this.validatePassportDateOfExpiry(question);
      } else if (
        // ! If these questions happen to have separate validation rules, split them
        question.code === QUESTION_CODE_ENUM.applicantEmail ||
        question.code === QUESTION_CODE_ENUM.hostEmail
      ) {
        isValid = isValid && this.validateEmailQuestion(question);
      } else if (question.code === QUESTION_CODE_ENUM.passportNumber) {
        isValid = isValid && this.validatePassportNumberQuestion(question) && question.error != 'passportNumberIsNotAllowedToApplyForVisa';
      } else if (question.code === QUESTION_CODE_ENUM.durationOfStay) {
        const visaTypeCategory = this.application.visaType.category
        if (visaTypeCategory === 'C') isValid = isValid && this.validateDurationOfStayTypeCQuestion(question);
      }

      // TODO: Add cases based on code.

      if (!isValid) {
        return false;
      }

      question.error = null;
      question.errorParams = {};
      question.errorCount = null;
      return true;
    },
    validateCurrentPageQuestions() {
      this.currentPageQuestions.forEach((question) => {
        this.validateQuestion(question);
      });

      return this.currentAnswersAreValid;
    },
    async onQuestionAnswered(payload) {
      if (this.applicationIsLocked) {
        return;
      }

      const answerIsNonNullObject =
        typeof payload.answer === "object" && payload.answer !== null;
      const answerIsNullOrEmpty =
        payload.answer === null || payload.answer === "";

      //! Select already return an object
      //! For inputs create object whose locales are the same
      const answer = answerIsNonNullObject
        ? payload.answer
        : answerIsNullOrEmpty
        ? null
        : { en: payload.answer, al: payload.answer };

      //! You can find the question via for loop, but the references stay the same
      //! const question = this.questions.find((question) => question.id === payload.question.id);
      const question = payload.question;
      const answerObject = {
        answer,
        id: null,
        applicationId: this.applicationId,
        questionId: payload.question.id,
        question: {
          en: payload.question.question.en,
          al: payload.question.question.al,
        },
      };

      if (question.answers.length) {
        //ID is only present if question is synched before to server
        answerObject.id = question.answers[0].id;

        if (!answer && !answerObject.id) {
          // answer is not synched, it can be directly removed
          question.answers.pop();
        } else if (!answer && answerObject.id) {
          // answer is syched with server, mark it to be deleted on sych operation
          answerObject.toDelete = true;

          question.answers.pop();
          question.answers.push(answerObject);
        } else if (answer) {
          // answer is updated
          question.answers.pop();
          question.answers.push(answerObject);
        }
      } else {
        // new answer
        question.answers.push(answerObject);
      }

      //! When question is dirty it is synchronized to server on blur/close
      question.dirty = true;
      this.validateQuestion(question);
    },
    ...questionSideEffectsAfterServerSync,
    async applyQuestionSideEffectsAfterServerSync(question) {
      // ! We are not filtering out the consular representations based on the country of residence for now
      // ! We'll have to re-activate it as a feature once we have migrated all the old data.
      if (question.code === QUESTION_CODE_ENUM.countryOfResidence) {
        return await this.applyCountryOfResidenceSideEffectsAfterServerSync(
          question
        );
      }

      if (question.code === QUESTION_CODE_ENUM.consularRepresentation) {
        return await this.applyConsularRepresentationSideEffectsAfterServerSync(
            question
        );
      }

      if (question.code === QUESTION_CODE_ENUM.currentNationality) {
        return await this.applyCurrentNationalitySideEffectsAfterServerSync(
          question
        );
      }

      if (question.code === QUESTION_CODE_ENUM.dateOfBirth) {
        return this.applyDateOfBirthSideEffectsAfterServerSync(question);
      }

      if (question.code === QUESTION_CODE_ENUM.residencePermit) {
        return this.applyResidencePermitSideEffectsAfterServerSync(question);
      }

      if (question.code === QUESTION_CODE_ENUM.dateOfArrival) {
        return this.applyDateOfArrivalSideEffectsAfterServerSync(question);
      }

      if (question.code === QUESTION_CODE_ENUM.dateOfDeparture) {
        return this.applyDateOfDepartureSideEffectsAfterServerSync(question);
      }

      if (question.code === QUESTION_CODE_ENUM.host) {
          const visaTypeCategoryGroup = this.application.visaType.categoryGroup
          if (visaTypeCategoryGroup === "D" || visaTypeCategoryGroup === "C")
            return this.applyHostSideEffectsAfterServerSync(question);
      }
    },
    async onQuestionSynched(payload) {
      if (this.applicationIsLocked) {
        return;
      }

      const question = this.questions.find(
        (question) => question.id === payload.question.id
      );

      if (!question.dirty) {
        return;
      }

      if (
        question.answers.length &&
        question.answers[0].toDelete &&
        question.answers[0].id &&
        !question.error
      ) {
        // TODO: Implement try and catch
        await Answer.delete(this.applicationId, question.answers[0].id);
        question.answers.pop();

        this.applyQuestionSideEffectsAfterServerSync(question);
        return;
      }

      if (question.error == 'passportNumberIsNotAllowedToApplyForVisa') {
        question.error = ''
      }

      if (question.answers.length && !question.error) {
        // TODO: Implement try and catch
        try {
          const { data: answerResponse } = await Answer.createOrUpdate(
              this.applicationId,
              question.answers[0]
          );

          question.answers[0].id = answerResponse.data.answer.id;
          question.dirty = false;
        } catch (e) {
          question.error = e.response.data.errors
        }
      }

      await this.applyQuestionSideEffectsAfterServerSync(question);
    },
    onDocumentUploaded(payload) {
      //! Can find by looping through all categories, but references are the same
      const documentCategory = payload.documentCategory;

      if (documentCategory.error) {
        documentCategory.error = null;
        documentCategory.errorParams = null;
      }

      documentCategory.documents.push(payload.document);
    },
    onDocumentDeleted(payload) {
      //! Can find by looping through all categories, but references are the same
      const documentCategory = payload.documentCategory;

      documentCategory.documents.pop();
    },
    onDocumentSizeError(payload) {
      //! Can find by looping through all categories, but references are the same
      const documentCategory = payload.documentCategory;

      documentCategory.error = "documentInvalidSize";
      documentCategory.errorCount = payload.count;
      documentCategory.errorParams = payload.error;
    },
    onDocumentTypeError(payload) {
      //! Can find by looping through all categories, but references are the same
      const documentCategory = payload.documentCategory;

      documentCategory.error = "documentInvalidType";
      documentCategory.errorCount = payload.count;
      documentCategory.errorParams = payload.error;
    },
    validateDocument(documentCategory) {
      if (documentCategory.isRequired && !documentCategory.documents.length) {
        documentCategory.error = "isRequired";
        documentCategory.errorCount = 1;
        documentCategory.errorParams = {};

        return false;
      }

      if (
        documentCategory.code ===
          DOCUMENT_CATEGORY_ENUM.under18parentalApproval &&
        this.under18DocumentIsRequired &&
        !documentCategory.documents.length
      ) {
        documentCategory.error = "isRequiredUnder18";
        documentCategory.errorCount = 1;
        documentCategory.errorParams = {};

        return false;
      }

      if (
          documentCategory.code ===
          DOCUMENT_CATEGORY_ENUM.residencePermitOver12Months &&
          this.residencePermitOver12MonthsIsRequired &&
          !documentCategory.documents.length
      ) {
        documentCategory.error = "isRequired";
        documentCategory.errorCount = 1;
        documentCategory.errorParams = {};

        return false;
      }
      return true;
    },
    validateDocuments() {
      let areValid = true;

      for (const documentCategory of this.documentCategories) {
        const categoryIsValid = this.validateDocument(documentCategory);

        areValid = areValid && categoryIsValid;
      }

      return areValid;
    },
    goToPage(pageDestination) {
      if (this.pagesOnlyGoBackward && pageDestination > this.page) {
        return;
      }

      if (!this.applicationIsLocked) {
        this.validateCurrentPageQuestions();

        if (!this.pagesOnlyGoBackward && !this.currentAnswersAreValid) {
          return;
        }
      }

      this.page = pageDestination;
    },
    goToNextPage() {
      if (this.isLastPage) {
        return;
      }

      if (!this.applicationIsLocked) {
        this.validateCurrentPageQuestions();

        if (!this.currentAnswersAreValid) {
          this.$nextTick(() => {
            this.$scrollTo("label.has-error");
          });
          return;
        }
      }

      this.page += 1;

      this.scrollToResponsive("#questions");
    },
    goToPrevPage() {
      if (this.isFirstPage) {
        return;
      }

      this.page -= 1;

      this.scrollToResponsive("#questions");
    },
    async finalizeApplication() {
      if (this.applicationIsLocked) {
        return;
      }

      if (!this.validateDocuments()) {
        this.$nextTick(() => {
          this.$scrollTo("label.has-error");
        });
        return;
      }

      if (this.isFinalizingApplication) {
        return;
      }

      this.isFinalizingApplication = true;

      try {
        await Application.finalize(this.applicationId);

        await this.$router.push({ name: "Applications" });
      } catch (err) {
        const status = err.response.status
        let text = `${this.$options.tPfx}.somethingWentWrongWithEmailAddress`;
        if (status === 422) {
          if (err.response.data.errors['blockedApplicantPassportNumber'] === true) {
            text = `${this.$options.tPfx}.blockedApplicantPassportNumber`
          } else {
            text = `${this.$options.tPfx}.validationFailed`
          }
        }
        await this.dispatchShowFlash({
          class:
            "sm:block md:inline-block border-secondary-500 bg-secondary-200 max-w-lg mx-auto sm:max-w-auto sm:min-w-lg",
          iconClass: "hover:text-secondary-500",
          text: text,
          textClass: "avoid-warp",
        });
      } finally {
        this.isFinalizingApplication = false;
      }
    },
    async showIrregularitiesComment() {
      if (this.application.status.code !== STATUS_ENUM.consularNeedChanges) {
        return;
      }

      await this.dispatchShowFlash({
        class:
          "sm:block md:inline-block border-secondary-500 bg-secondary-200 max-w-lg mx-auto sm:max-w-auto sm:min-w-lg",
        iconClass: "hover:text-secondary-500",
        text: this.application.status.comment,
        textClass: "avoid-warp",
      });
    },
  },
  async mounted() {
    await this.loadApplication();
  },
};
</script>

<style>
</style>
