<template functional>
  <footer class="bg-primary-900 p-8 pt-12 text-center md:text-left">
    <tapered-section class="flex flex-wrap">
      <section class="w-full md:w-1/2 lg:w-1/4 px-6 pb-6 text-white">
<!--        <img-->
<!--          class="w-32 inline-block md:block mx-2"-->
<!--          src="/img/visa.png"-->
<!--          alt="visa-card"-->
<!--        />-->
<!--        <img-->
<!--          class="w-32 inline-block md:block mx-2"-->
<!--          src="/img/mastercard.png"-->
<!--          alt="mastercard"-->
<!--        />-->
      </section>
      <section class="w-full md:w-1/2 lg:w-1/4 p-6 text-white">
        <header class="py-4">
          <h3>{{ parent.$t(`${$options.tPfx}.application`) }}</h3>
        </header>
        <ul>
          <li>
            <router-link
              class="hover:underline"
              :to="{ name: 'Apply', hash: '#visa-types' }"
            >
              {{ parent.$t(`${$options.tPfx}.newApplication`) }}
            </router-link>
          </li>
          <li>
            <router-link class="hover:underline" :to="{ name: 'Applications' }">
              {{ parent.$t(`${$options.tPfx}.ongoingApplication`) }}
            </router-link>
          </li>
          <li>
            <router-link class="hover:underline" :to="{ name: 'Track' }">
              {{ parent.$t(`${$options.tPfx}.track`) }}
            </router-link>
          </li>
        </ul>
      </section>
      <section class="w-full md:w-1/2 lg:w-1/4 p-6 text-white">
        <header class="py-4">
          <h3>{{ parent.$t(`${$options.tPfx}.aboutEVisa`) }}</h3>
        </header>
        <ul>
          <li>
            <router-link class="hover:underline" to="#">
              {{ parent.$t(`${$options.tPfx}.about`) }}
            </router-link>
          </li>
          <li>
            <router-link class="hover:underline" to="#">
              {{ parent.$t(`${$options.tPfx}.faq`) }}
            </router-link>
          </li>
          <li>
            <router-link class="hover:underline" to="#">
              {{ parent.$t(`${$options.tPfx}.comments`) }}
            </router-link>
          </li>
        </ul>
      </section>
      <section class="w-full md:w-1/2 lg:w-1/4 p-6 text-white">
<!--        <header class="py-4">-->
<!--          <h3>{{ parent.$t(`${$options.tPfx}.contact`) }}</h3>-->
<!--        </header>-->
<!--        <p>Visa@mfa.gov.al</p>-->
      </section>
      <section class="mt-6 w-full text-gray-600">
        <p class="text-sm pl-6">
          {{
            parent.$t(`${$options.tPfx}.copyright`, {
              currentYear: $options.currentYear,
            })
          }}
        </p>
      </section>
    </tapered-section>
  </footer>
</template>

<script>
export const translationPrefix = "layout.default.footer";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      application: "Application",
      newApplication: "New application",
      ongoingApplication: "Ongoing applications",
      track: "Track application",
      aboutEVisa: "About e-Visa",
      about: "About",
      faq: "FAQ",
      comments: "Comments",
      contact: "Contact",
      copyright: "This electronic system is maintained by the economic operator: Kreatx",
    },
    al: {
      application: "Aplikimi",
      newApplication: "Aplikim i ri",
      ongoingApplication: "Aplikimet në proces",
      track: "Gjurmo aplikim",
      aboutEVisa: "Rreth e-Visa",
      about: "Rreth nesh",
      faq: "FAQ",
      comments: "Komente",
      contact: "Kontakt",
      copyright:
        "Ky sistem elektronik mirëmbahet nga operatori ekonomik: Kreatx",
    },
  },
};

const currentYear = new Date().getFullYear();

export default {
  tPfx: translationPrefix,
  currentYear,
};
</script>