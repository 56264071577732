<template functional>
  <section
    :class="[
      data.class,
      data.staticClass,
      props.isSelected
        ? 'border-solid border-secondary-500'
        : 'border-dashed border-gray-400 hover:border-secondary-500',
    ]"
    v-bind="data.attrs"
    v-on="listeners"
    class="relative group my-4 rounded border-4 max-w-md xl:max-w-lg p-8 bg-white cursor-pointer"
  >
    <header class="mb-4">
      <h2>{{ props.title }}</h2>
      <h3>{{ props.subtitle }}</h3>
    </header>
    <p>
      {{ props.description }}
    </p>

    <div v-if="props.eAlbaniaLink">
      <br>

      <p class="mb-2">
        {{ parent.$t(`${$options.tPfx}.linkForApplication`) }}
      </p>
      <a :href="parent.$t(`${$options.tPfx}.eAlbaniaLinkRedirect`)" class="link-preview-text">
        {{ parent.$t(`${$options.tPfx}.eAlbaniaLinkRedirect`) }}
      </a>
    </div>

    <p>
      <br>
      {{ props.info }}
    </p>

    <span
      v-show="!props.isSelected"
      class="absolute hidden group-hover:inline top-0 right-0 p-2 border-b-4 border-l-4 border-dashed rounded-bl-md border-secondary-500 text-secondary-500 leading-none font-bold bg-secondary-100 text-xs uppercase"
    >
      {{ parent.$t(`${$options.tPfx}.select`) }}
    </span>

    <span
      v-show="props.isSelected"
      class="absolute top-0 right-0 p-2 border-b-4 border-l-4 rounded-bl-md border-secondary-500 text-secondary-500 leading-none font-bold bg-secondary-100 text-xs group-hover:bg-secondary-300 group-hover:text-white uppercase"
    >
      <span v-if="props.hideRemoveButton">
         {{ parent.$t(`${$options.tPfx}.selected`) }}
      </span>
      <div v-else>
        <span class="group-hover:hidden">
        {{ parent.$t(`${$options.tPfx}.selected`) }}
      </span>
      <span class="hidden group-hover:inline">
        {{ parent.$t(`${$options.tPfx}.deselect`) }}
      </span>
      </div>
    </span>
  </section>
</template>

<script>

export const translationPrefix = "view.apply.visas.visaTypeCategory";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      select: "Select",
      selected: "Selected",
      deselect: "Deselect",
      linkForApplication: "LINK FOR THE APPLICATION: ",
      eAlbaniaLinkRedirect: "https://e-albania.al/eAlbaniaServices/DPPSH/15388/DPPSH_15388_n4_s1_web.aspx?service_code=15388",
    },
    al: {
      select: "Zgjidh",
      selected: "Zgjedhur",
      deselect: "Hiq",
      linkForApplication: "LINK PËR APLIKIMIN: ",
      eAlbaniaLinkRedirect: "https://e-albania.al/eAlbaniaServices/DPPSH/15388/DPPSH_15388_n4_s1_web.aspx?service_code=15388",
    },
  },
};


export default {
  tPfx: translationPrefix,
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    eAlbaniaLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    info: {
      type: String,
      required: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    hideRemoveButton: {
      type: Boolean,
      default: false,
    }
  },
};
</script>
