var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col md:flex-row justify-center"},[(_vm.canDownloadGeneratedVisa)?_c('button',{staticClass:"button button-compact button-green px-3",attrs:{"title":_vm.$t(((_vm.$options.tPfx) + ".downloadVisa"))},on:{"click":_vm.downloadGeneratedVisa}},[_c('font-awesome-icon',{staticClass:"text-white",attrs:{"icon":"file-download"}})],1):_vm._e(),(_vm.canCancel)?_c('button',{staticClass:"button button-compact button-secondary px-3",attrs:{"title":_vm.$t(((_vm.$options.tPfx) + ".cancelApplication"))},on:{"click":_vm.cancelApplication}},[_c('font-awesome-icon',{staticClass:"text-white",attrs:{"icon":"trash"}})],1):_vm._e(),(_vm.needsPayment)?_c('router-link',{attrs:{"to":{ name: 'Payment', params: { applicationId: _vm.application.id } }}},[_c('button',{staticClass:"button button-compact button-secondary px-3",attrs:{"title":_vm.$t(((_vm.$options.tPfx) + ".pay"))}},[_c('font-awesome-icon',{staticClass:"text-white",attrs:{"icon":"euro-sign"}})],1)]):_vm._e(),(_vm.canAppeal)?_c('router-link',{attrs:{"to":{ name: 'Appeal', params: { applicationId: _vm.application.id } }}},[_c('button',{staticClass:"button button-compact button-secondary px-3",attrs:{"title":_vm.$t(((_vm.$options.tPfx) + ".appeal"))}},[_c('font-awesome-icon',{staticClass:"text-white",attrs:{"icon":"gavel"}})],1)]):_vm._e(),_c('router-link',{staticClass:"mt-2 md:ml-2 md:mt-0",attrs:{"target":_vm.hasActionsOtherThanViewOrEdit ? '_blank' : '',"to":{
      name: 'Questionary',
      params: { applicationId: _vm.application.id },
    }}},[_c('button',{staticClass:"button button-compact",class:{
        'button-info': !_vm.canBeEdited,
        'button-yellow': _vm.canBeEdited && _vm.isDraft,
        'button-secondary': _vm.canBeEdited && _vm.hasIrregularities,
      },attrs:{"title":_vm.$t(((_vm.$options.tPfx) + "." + (_vm.canBeEdited ? 'edit' : 'view')))}},[_c('font-awesome-icon',{staticClass:"text-white",attrs:{"icon":_vm.canBeEdited ? 'edit' : 'eye'}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }